import React from 'react'
import '../components/index.scss'
export default class Default extends React.Component {
  componentDidMount() {
    // comment in dev env
    window.location.replace('https://celerity.avitomedia.com/')
  }
  render() {
    return <h1>redirecting ...</h1>
  }
}
